
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  toRefs,
  toRaw,
  ref,
  watch,
  ComputedRef,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FolderRequest } from "../../core/types/FolderTypes";
import { JobMatchToBehavioralSurveyRequestDto } from "../../core/types/AnalysisTypes";
import { FolderSurveyLineItemDto } from "../../core/types/SurveyTypes";
import {
  ExportJOBMatchToBehavioralRequestDto,
  ExportToExcelResponseDto,
} from "../../core/types/ExcelTypes";
import * as Yup from "yup";

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedBehavioralFolder: number;
  selectedJobLocationFolder: number;
  selectedJobSurvey: number;
}

export default defineComponent({
  name: "match-job-survey-to-behvioral",
  components: { Field, ErrorMessage, Form },
  props: {
    surveyid: {
      type: [String],
      default: "0",
    },
    folderid: {
      type: [String],
      default: "0",
    },
    clientid: {
      type: [String],
      default: "0",
    },
    licenseddistributorid: {
      type: [String],
      default: "0",
    },
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedBehavioralFolder: 0,
      selectedJobLocationFolder: 0,
      selectedJobSurvey: 0,
    };
    const runReportButton = ref<HTMLElement | null>(null);
    const exportToExcelButton = ref<HTMLButtonElement | null>(null);
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const licensedDistributorId = computed(() => {
      return store.getters.getLicensedDistId;
    });
    const islicensedDistributorIdReady = ref(false);
    const licensedDistributorAssocId = computed(() => {
      return store.getters.getLDAssociatesLicensedDistributorId;
    });
    const islicensedDistributorAssocIdReady = ref(false);
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const jobFolderRequest = ref<FolderRequest>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      folderId: 0,
      folderName: "JOB-Surveys",
      getAllSurveys: false,
      clientId: 0,
      clientLocationId: 0,
    });
    const isCurrentClientReady = ref(false);
    const areResultsReady = ref(false);
    const jobMatchToBehavioralSurveyRequestDto =
      ref<JobMatchToBehavioralSurveyRequestDto>({
        jobSurveyId: 0,
        behavioralLocationFolderId: 0,
        clientId: 0,
      });
    const jobSurveyToMatch = computed(() => {
      return store.getters.getSurveyById(
        Number(dropDownSetup.selectedJobSurvey)
      );
    });
    const isExcelFileReady = ref(false);

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Match JOB Survey to Behavioral Surveys", [
        "Survey Analysis",
      ]);
      console.log("surveyid: " + props.surveyid);
      console.log("folderid: " + props.folderid);
      console.log("clientid: " + props.clientid);
      console.log("licenseddistributorid: " + props.licenseddistributorid);

      if (currentUser.role === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (currentUser.role === "LicensedDistributor") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_ID, currentUser.id)
          .then(() => {
            dropDownSetup.selectedLicensedDistributor =
              licensedDistributorId.value;
            getClients();
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributor id.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (currentUser.role === "LDAssociate") {
        store
          .dispatch(
            Actions.GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID,
            currentUser.id
          )
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributor assoc id.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const clientJobFolderList = computed(() => {
      return store.getters.getClientJOBFolderLocationDDL;
    });

    const jobSurveyList = computed(() => {
      return store.getters.getFolderContents;
    });

    const jobSurveyMatchResults = computed(() => {
      return store.getters.matchJOBSurveyToBehavioralResults;
    });

    const searchSurveys = ref("");

    const validateForm = Yup.object().shape({
      jobSurveyLocation: Yup.number()
        .min(10, "JOB Survey Location is required")
        .required()
        .label("Job Survey Location"),
      jobSurvey: Yup.number()
        .min(10, "JOB Survey is required")
        .required()
        .label("Job Survey"),
      behavioralSurveyLocation: Yup.number()
        .min(10, "Behavioral Survey Location is required")
        .required()
        .label("Behavioral Survey Location"),
    });
    const exportJOBMatchToBehavioralRequestDto =
      ref<ExportJOBMatchToBehavioralRequestDto>({
        jobSurveyId: 0,
        behavioralLocationFolderId: 0,
        clientId: 0,
        lineItems: [],
      });
    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile;
      }
    );
    const getClients = () => {
      resetResultAreReady();

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      resetResultAreReady();

      if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        if (props.surveyid !== "0") {
          dropDownSetup.selectedClient = Number(props.clientid);
        } else {
          dropDownSetup.selectedClient = currentClient.value.client.clientId;
        }
      }

      store
        .dispatch(
          Actions.GET_CLIENT_BEHAVIORAL_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving behavioral survey location folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      store
        .dispatch(
          Actions.GET_CLIENT_JOB_FOLDER_LOCATION_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving job survey location folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getJOBSurveys = () => {
      resetResultAreReady();

      jobFolderRequest.value.folderId = dropDownSetup.selectedJobLocationFolder;
      jobFolderRequest.value.clientId = dropDownSetup.selectedClient;
      if (dropDownSetup.selectedJobLocationFolder == 10) {
        jobFolderRequest.value.getAllSurveys = true;
      }

      store
        .dispatch(Actions.GET_CLIENT_FOLDER_CONTENTS, jobFolderRequest.value)
        .then(() => {
          //
          if (jobSurveyList.value.length === 0) {
            Swal.fire({
              text: "No surveys were found.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving job folder contents.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const onSubmitRunReport = () => {
      // Activate indicator
      runReportButton.value?.setAttribute("data-kt-indicator", "on");

      isExcelFileReady.value = false;

      jobMatchToBehavioralSurveyRequestDto.value.jobSurveyId =
        dropDownSetup.selectedJobSurvey;
      jobMatchToBehavioralSurveyRequestDto.value.behavioralLocationFolderId =
        dropDownSetup.selectedBehavioralFolder;
      jobMatchToBehavioralSurveyRequestDto.value.clientId =
        dropDownSetup.selectedClient;

      store
        .dispatch(
          Actions.POST_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY,
          jobMatchToBehavioralSurveyRequestDto.value
        )
        .then(() => {
          //
          runReportButton.value?.removeAttribute("data-kt-indicator");
          areResultsReady.value = true;
        })
        .catch(() => {
          runReportButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving behavioral survey matches",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return jobSurveyMatchResults.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      }
    );

    watch(licensedDistributors, () => {
      console.log("licenseddistwatched");
      if (props.licenseddistributorid != "0") {
        dropDownSetup.selectedLicensedDistributor = Number(
          props.licenseddistributorid
        );
        getClients();
      }
    });

    watch(licensedDistributorAssocId, () => {
      islicensedDistributorAssocIdReady.value = true;
      dropDownSetup.selectedLicensedDistributor =
        licensedDistributorAssocId.value;
      getClients();
    });

    watch(currentClient, () => {
      isCurrentClientReady.value = true;
      getClientFolders();
    });

    watch(clientList, () => {
      if (props.clientid !== "0") {
        dropDownSetup.selectedClient = Number(props.clientid);
        getClientFolders();
      }
    });

    watch(clientFolderList, () => {
      if (props.folderid !== "0") {
        dropDownSetup.selectedJobLocationFolder = Number(props.folderid);
        getJOBSurveys();
      }
    });

    watch(jobSurveyList, () => {
      if (props.surveyid !== "0") {
        dropDownSetup.selectedJobSurvey = Number(props.surveyid);
      }
    });

    watch(jobSurveyMatchResults, () => {
      areResultsReady.value = true;
    });

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== "na") {
        isExcelFileReady.value = true;

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = false;
      } else if (excelFileResponse.value.excelFilename === "na") {
        isExcelFileReady.value = false;
      }
    });

    function resetResultAreReady() {
      areResultsReady.value = false;
    }

    function exportToExcel() {
      exportJOBMatchToBehavioralRequestDto.value.lineItems =
        jobSurveyMatchResults.value;
      exportJOBMatchToBehavioralRequestDto.value.jobSurveyId =
        dropDownSetup.selectedJobSurvey;
      exportJOBMatchToBehavioralRequestDto.value.behavioralLocationFolderId =
        dropDownSetup.selectedBehavioralFolder;
      exportJOBMatchToBehavioralRequestDto.value.clientId =
        dropDownSetup.selectedClient;

      store.commit(Mutations.PURGE_EXCEL_FILE);

      store
        .dispatch(
          Actions.EXPORT_JOB_MATCH_TO_BEHAVIORAL_SURVEYS_TO_EXCEL,
          exportJOBMatchToBehavioralRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true;
        // Activate indicator
        exportToExcelButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    return {
      currentUser,
      jobFolderRequest,
      licensedDistributorId,
      islicensedDistributorIdReady,
      licensedDistributorAssocId,
      islicensedDistributorAssocIdReady,
      currentClient,
      isCurrentClientReady,
      areResultsReady,
      jobMatchToBehavioralSurveyRequestDto,
      jobSurveyToMatch,
      ...toRefs(dropDownSetup),
      getClients,
      getClientFolders,
      getJOBSurveys,
      licensedDistributors,
      clientList,
      clientFolderList,
      clientJobFolderList,
      jobSurveyList,
      jobSurveyMatchResults,
      filterSurveys,
      searchSurveys,
      validateForm,
      onSubmitRunReport,
      runReportButton,
      exportToExcelButton,
      resetResultAreReady,
      exportToExcel,
      excelFileResponse,
      isExcelFileReady,
    };
  },
});
