<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body px-0 pt-3 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          novalidate="novalidate"
          @submit="onSubmitRunReport"
          id="matchJob_to_behavioralProfile_form"
          :validation-schema="validateForm"
        >
          <div class="card-header cursor-pointer flex-column">
            <!--begin::Card title-->
            <div class="card-title d-flex flex-column mb-3">
              <h3 class="fw-bolder m-0">Match JOB To Behavioral Surveys</h3>
            </div>
            <!--end::Card title-->
            <div
              class="d-flex flex-column"
              v-if="currentUser.role === 'SuperAdmin'"
            >
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    mb-3
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">
                    Licensed Distributor
                  </h5>
                  <!--begin:: Licensed Distributor Dropdown-->
                  <div>
                    <select
                      name="licensedDistributor"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedLicensedDistributor"
                      @change="getClients()"
                    >
                      <option value="0">Select Licensed Distributor...</option>
                      <option
                        v-for="item in licensedDistributors"
                        :key="item.licensedDistributorId"
                        :label="item.name"
                        :value="item.licensedDistributorId"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <!--end:: Licensed Distributo Dropdown-->
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column"
              v-if="
                currentUser.role === 'SuperAdmin' ||
                currentUser.role === 'LicensedDistributor' ||
                currentUser.role === 'LDAssociate'
              "
            >
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    mb-3
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">Client</h5>
                  <!--begin Select Client Dropdown-->
                  <div>
                    <Field
                      name="client"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedClient"
                      @change="getClientFolders()"
                      label="client"
                    >
                      <option value="0">Select Client...</option>
                      <option
                        v-for="item in clientList"
                        :key="item.clientId"
                        :label="item.name"
                        :value="item.clientId"
                      >
                        {{ item.name }}
                      </option>
                    </Field>
                  </div>
                  <!--end:: Select Client Dropdown-->
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    me-sm-3
                    mb-3 mb-sm-0
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">JOB Survey To Match</h5>
                  <!--begin Select JOB Survey Location-->
                  <div>
                    <label class="required fs-6 fw-bold mb-2"
                      >Job Survey Location</label
                    >
                    <Field
                      name="jobSurveyLocation"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedJobLocationFolder"
                      @change="getJOBSurveys()"
                    >
                      <option value="0">Select JOB Location Folder...</option>
                      <option value="10">All Locations</option>
                      <option
                        v-for="item in clientJobFolderList"
                        :key="item.folderId"
                        :label="item.folderName"
                        :value="item.folderId"
                      >
                        {{ item.folderName }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container mb-3">
                      <div class="fv-help-block">
                        <ErrorMessage name="jobSurveyLocation" />
                      </div>
                    </div>
                  </div>
                  <!--end:: Select JOB Survey Location-->
                  <!--begin Select JOB Survey -->
                  <div>
                    <label class="required fs-6 fw-bold mb-2">Job Survey</label>
                    <Field
                      name="jobSurvey"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedJobSurvey"
                      @change="resetResultAreReady()"
                    >
                      <option value="0">Select JOB Survey...</option>
                      <option
                        v-for="item in jobSurveyList"
                        :key="item.surveyId"
                        :label="item.surveyJobTitle + ': ' + item.name"
                        :value="item.surveyId"
                      >
                        {{ item.surveyJobTitle + ": " + item.name }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="jobSurvey" />
                      </div>
                    </div>
                  </div>
                  <!--end:: Select JOB Survey -->
                </div>
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">
                    Behavioral Surveys to Search
                  </h5>

                  <!--begin Select Behavioral Survey Location-->
                  <div>
                    <label class="required fs-6 fw-bold mb-2"
                      >Behavioral Survey Location</label
                    >
                    <Field
                      name="behavioralSurveyLocation"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedBehavioralFolder"
                      @change="resetResultAreReady()"
                    >
                      <option value="0">
                        Select Behavioral Location Folder...
                      </option>
                      <option value="10">All Locations</option>
                      <option
                        v-for="item in clientFolderList"
                        :key="item.folderId"
                        :label="item.folderName"
                        :value="item.folderId"
                      >
                        {{ item.folderName }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="behavioralSurveyLocation" />
                      </div>
                    </div>
                  </div>
                  <!--end:: Select Behavrioal Survey Location-->
                </div>
              </div>
              <div class="d-flex flex-row my-3">
                <button
                  tabindex="3"
                  type="submit"
                  ref="runReportButton"
                  id="runReportBtn"
                  class="btn btn-primary flex-grow-1"
                >
                  <span class="indicator-label"> Run Report </span>

                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Form>
        <!--end::Form-->
        <!--begin::JOB Survey Grid-->
        <div class="d-flex flex-column my-3" v-if="areResultsReady">
          <div class="d-flex mb-0 justify-content-center">
            <h5>Matching JOB Survey</h5>
          </div>
          <div class="d-flex justify-content-center">
            <h4>{{ jobSurveyToMatch.jobTitle }} {{ jobSurveyToMatch.name }}</h4>
          </div>
          <div class="d-flex w-sm-50 px-4 mb-3 mx-auto">
            <img
              class="img-fluid"
              :src="'/job-result-grids/' + jobSurveyToMatch.graphFilename"
              alt="Job Survey Grid"
            />
          </div>
        </div>
        <!--end::JOB Survey Grid-->
        <!--begin::Card body-->
        <div class="card-body pt-5" v-if="areResultsReady">
          <!--begin:: Flex Container-->
          <div class="d-flex flex-column-reverse flex-sm-row">
            <!--begin::Search-->
            <input
              type="text"
              class="form-control form-control-lg form-control-solid px-5 mb-2"
              v-model="searchSurveys"
              name="search"
              placeholder="Search by name"
              data-kt-search-element="input"
            />
            <!--end::Search-->

            <button
              tabindex="3"
              type="submit"
              ref="exportToExcelButton"
              id="exportToExcelBtn"
              class="btn btn-primary mb-2 w-21-sm"
              @click="exportToExcel()"
              v-if="isExcelFileReady === false"
            >
              <span class="indicator-label"> Export To Excel </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <a
              v-if="isExcelFileReady"
              :href="'/excel/job-match-surveys/' + excelFileResponse"
              class="btn btn-sm btn-success"
              style="width: 250px; font-size: 16px"
              download
              >Download Excel File
              <img
                style="max-width: 20px"
                src="/media/icons/duotune/files/fil009.svg"
              />
            </a>
          </div>

          <div class="d-flex flex-column" v-if="areResultsReady">
            <!--begin:: Narrative Result -->
            <div
              v-for="(item, index) in filterSurveys"
              :key="index"
              class="d-flex flex-row mb-2"
            >
              <div
                class="d-flex flex-row flex-wrap pt-2"
                :class="
                  'incline-primary-' +
                  item.jobMatchToProfileColor +
                  '-gradient-bkgrd'
                "
              >
                <!--begin:: Name-->
                <div class="mb-2 pt-5 ps-4 pe-sm-4">
                  <p class="mb-0 pt-2 pt-sm-5">
                    <strong>{{ item.name }}</strong>
                  </p>
                  <p class="mb-0" style="font-size: 11px">
                    <a :href="'mailto:' + item.email">{{ item.email }}</a>
                  </p>
                  <p class="mb-1" style="font-size: 11px">
                    <i>{{ item.jobTitle }}</i>
                  </p>
                  <p class="mb-0" style="font-size: 13px">
                    <span style="font-size: 11px; font-style: italic"
                      >profile:</span
                    >
                    {{ item.profileName }}
                  </p>
                  <p class="mb-0" style="font-size: 13px">
                    <span style="font-size: 11px; font-style: italic"
                      >location:</span
                    >
                    {{ item.location }}
                  </p>
                  <p class="mb-0 pt-sm-5" style="font-size: 14px">
                    <strong v-if="item.jobMatchToProfileColor === 'green'"
                      >Close Match</strong
                    >
                    <strong v-else-if="item.jobMatchToProfileColor === 'yellow'"
                      >Marginal Match</strong
                    >
                    <strong v-else-if="item.jobMatchToProfileColor === 'red'"
                      >Not Close Match</strong
                    >
                  </p>
                </div>
                <!--end:: Name-->
                <!--begin:: Reports-->
                <div
                  class="
                    d-flex
                    flex-row
                    justify-content-between
                    flex-md-column
                    justify-content-md-start
                    pt-sm-5
                    pe-sm-2
                  "
                >
                  <a
                    :href="
                      '/pdfs/incline-behavioral-narratives/' +
                      item.behavioralNarrativePdf
                    "
                    target="_blank"
                    class="me-2 mb-2 pt-sm-5 d-flex flex-row p-0"
                  >
                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                      <inline-svg src="/media/icons/duotune/files/fil009.svg" />
                    </span>
                    <strong>Narrative</strong>
                  </a>
                  <a
                    :href="
                      '/pdfs/incline-natural-vs-environmental/' +
                      item.naturalVersusEnvironmentalPdf
                    "
                    target="_blank"
                    class="me-2 mb-2 d-flex flex-row p-0"
                  >
                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                      <inline-svg src="/media/icons/duotune/files/fil009.svg" />
                    </span>
                    <strong>Graphs</strong>
                  </a>
                  <a
                    :href="
                      '/pdfs/incline-coaching-guides/' + item.coachingGuidePdf
                    "
                    target="_blank"
                    class="me-2 mb-2 d-flex flex-row p-0"
                  >
                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                      <inline-svg src="/media/icons/duotune/files/fil009.svg" />
                    </span>
                    <strong>Add'l Resources</strong>
                  </a>
                </div>
                <!--end:: Reports-->
                <!--begin:: Survey Grid-->
                <div class="d-flex pt-sm-4 pb-5 image-sm-width image-md-width">
                  <img
                    class="img-fluid"
                    :src="'/survey-result-grids/' + item.graphFilename"
                    alt="Behavioral Grid"
                  />
                </div>
                <!--end:: Survey Grid-->
              </div>
            </div>
            <!--end:: Narrative Result -->
          </div>
        </div>
        <!--end::Flex Container-->
        <!--end::Card body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  toRefs,
  toRaw,
  ref,
  watch,
  ComputedRef,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FolderRequest } from "../../core/types/FolderTypes";
import { JobMatchToBehavioralSurveyRequestDto } from "../../core/types/AnalysisTypes";
import { FolderSurveyLineItemDto } from "../../core/types/SurveyTypes";
import {
  ExportJOBMatchToBehavioralRequestDto,
  ExportToExcelResponseDto,
} from "../../core/types/ExcelTypes";
import * as Yup from "yup";

interface DropDownSetup {
  selectedLicensedDistributor: number;
  selectedClient: number;
  selectedBehavioralFolder: number;
  selectedJobLocationFolder: number;
  selectedJobSurvey: number;
}

export default defineComponent({
  name: "match-job-survey-to-behvioral",
  components: { Field, ErrorMessage, Form },
  props: {
    surveyid: {
      type: [String],
      default: "0",
    },
    folderid: {
      type: [String],
      default: "0",
    },
    clientid: {
      type: [String],
      default: "0",
    },
    licenseddistributorid: {
      type: [String],
      default: "0",
    },
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const initialDropDownSetup: DropDownSetup = {
      selectedLicensedDistributor: 0,
      selectedClient: 0,
      selectedBehavioralFolder: 0,
      selectedJobLocationFolder: 0,
      selectedJobSurvey: 0,
    };
    const runReportButton = ref<HTMLElement | null>(null);
    const exportToExcelButton = ref<HTMLButtonElement | null>(null);
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const licensedDistributorId = computed(() => {
      return store.getters.getLicensedDistId;
    });
    const islicensedDistributorIdReady = ref(false);
    const licensedDistributorAssocId = computed(() => {
      return store.getters.getLDAssociatesLicensedDistributorId;
    });
    const islicensedDistributorAssocIdReady = ref(false);
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const jobFolderRequest = ref<FolderRequest>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      folderId: 0,
      folderName: "JOB-Surveys",
      getAllSurveys: false,
      clientId: 0,
      clientLocationId: 0,
    });
    const isCurrentClientReady = ref(false);
    const areResultsReady = ref(false);
    const jobMatchToBehavioralSurveyRequestDto =
      ref<JobMatchToBehavioralSurveyRequestDto>({
        jobSurveyId: 0,
        behavioralLocationFolderId: 0,
        clientId: 0,
      });
    const jobSurveyToMatch = computed(() => {
      return store.getters.getSurveyById(
        Number(dropDownSetup.selectedJobSurvey)
      );
    });
    const isExcelFileReady = ref(false);

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Match JOB Survey to Behavioral Surveys", [
        "Survey Analysis",
      ]);
      console.log("surveyid: " + props.surveyid);
      console.log("folderid: " + props.folderid);
      console.log("clientid: " + props.clientid);
      console.log("licenseddistributorid: " + props.licenseddistributorid);

      if (currentUser.role === "SuperAdmin") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_DDL)
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributors.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (currentUser.role === "LicensedDistributor") {
        store
          .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_ID, currentUser.id)
          .then(() => {
            dropDownSetup.selectedLicensedDistributor =
              licensedDistributorId.value;
            getClients();
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributor id.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (currentUser.role === "LDAssociate") {
        store
          .dispatch(
            Actions.GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID,
            currentUser.id
          )
          .then(() => {
            //
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText:
                "There was a problem retrieving licensed distributor assoc id.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } else if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        getClientFolders();
      }
    });

    const licensedDistributors = computed(() => {
      return store.getters.getLicensedDistributorDDL;
    });

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const clientFolderList = computed(() => {
      return store.getters.getClientFoldersDDL;
    });

    const clientJobFolderList = computed(() => {
      return store.getters.getClientJOBFolderLocationDDL;
    });

    const jobSurveyList = computed(() => {
      return store.getters.getFolderContents;
    });

    const jobSurveyMatchResults = computed(() => {
      return store.getters.matchJOBSurveyToBehavioralResults;
    });

    const searchSurveys = ref("");

    const validateForm = Yup.object().shape({
      jobSurveyLocation: Yup.number()
        .min(10, "JOB Survey Location is required")
        .required()
        .label("Job Survey Location"),
      jobSurvey: Yup.number()
        .min(10, "JOB Survey is required")
        .required()
        .label("Job Survey"),
      behavioralSurveyLocation: Yup.number()
        .min(10, "Behavioral Survey Location is required")
        .required()
        .label("Behavioral Survey Location"),
    });
    const exportJOBMatchToBehavioralRequestDto =
      ref<ExportJOBMatchToBehavioralRequestDto>({
        jobSurveyId: 0,
        behavioralLocationFolderId: 0,
        clientId: 0,
        lineItems: [],
      });
    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile;
      }
    );
    const getClients = () => {
      resetResultAreReady();

      store
        .dispatch(
          Actions.GET_CLIENTS_DDL,
          toRaw(dropDownSetup.selectedLicensedDistributor)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getClientFolders = () => {
      resetResultAreReady();

      if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        if (props.surveyid !== "0") {
          dropDownSetup.selectedClient = Number(props.clientid);
        } else {
          dropDownSetup.selectedClient = currentClient.value.client.clientId;
        }
      }

      store
        .dispatch(
          Actions.GET_CLIENT_BEHAVIORAL_FOLDERS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving behavioral survey location folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      store
        .dispatch(
          Actions.GET_CLIENT_JOB_FOLDER_LOCATION_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving job survey location folders.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getJOBSurveys = () => {
      resetResultAreReady();

      jobFolderRequest.value.folderId = dropDownSetup.selectedJobLocationFolder;
      jobFolderRequest.value.clientId = dropDownSetup.selectedClient;
      if (dropDownSetup.selectedJobLocationFolder == 10) {
        jobFolderRequest.value.getAllSurveys = true;
      }

      store
        .dispatch(Actions.GET_CLIENT_FOLDER_CONTENTS, jobFolderRequest.value)
        .then(() => {
          //
          if (jobSurveyList.value.length === 0) {
            Swal.fire({
              text: "No surveys were found.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Okay",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving job folder contents.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const onSubmitRunReport = () => {
      // Activate indicator
      runReportButton.value?.setAttribute("data-kt-indicator", "on");

      isExcelFileReady.value = false;

      jobMatchToBehavioralSurveyRequestDto.value.jobSurveyId =
        dropDownSetup.selectedJobSurvey;
      jobMatchToBehavioralSurveyRequestDto.value.behavioralLocationFolderId =
        dropDownSetup.selectedBehavioralFolder;
      jobMatchToBehavioralSurveyRequestDto.value.clientId =
        dropDownSetup.selectedClient;

      store
        .dispatch(
          Actions.POST_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY,
          jobMatchToBehavioralSurveyRequestDto.value
        )
        .then(() => {
          //
          runReportButton.value?.removeAttribute("data-kt-indicator");
          areResultsReady.value = true;
        })
        .catch(() => {
          runReportButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving behavioral survey matches",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return jobSurveyMatchResults.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      }
    );

    watch(licensedDistributors, () => {
      console.log("licenseddistwatched");
      if (props.licenseddistributorid != "0") {
        dropDownSetup.selectedLicensedDistributor = Number(
          props.licenseddistributorid
        );
        getClients();
      }
    });

    watch(licensedDistributorAssocId, () => {
      islicensedDistributorAssocIdReady.value = true;
      dropDownSetup.selectedLicensedDistributor =
        licensedDistributorAssocId.value;
      getClients();
    });

    watch(currentClient, () => {
      isCurrentClientReady.value = true;
      getClientFolders();
    });

    watch(clientList, () => {
      if (props.clientid !== "0") {
        dropDownSetup.selectedClient = Number(props.clientid);
        getClientFolders();
      }
    });

    watch(clientFolderList, () => {
      if (props.folderid !== "0") {
        dropDownSetup.selectedJobLocationFolder = Number(props.folderid);
        getJOBSurveys();
      }
    });

    watch(jobSurveyList, () => {
      if (props.surveyid !== "0") {
        dropDownSetup.selectedJobSurvey = Number(props.surveyid);
      }
    });

    watch(jobSurveyMatchResults, () => {
      areResultsReady.value = true;
    });

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== "na") {
        isExcelFileReady.value = true;

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = false;
      } else if (excelFileResponse.value.excelFilename === "na") {
        isExcelFileReady.value = false;
      }
    });

    function resetResultAreReady() {
      areResultsReady.value = false;
    }

    function exportToExcel() {
      exportJOBMatchToBehavioralRequestDto.value.lineItems =
        jobSurveyMatchResults.value;
      exportJOBMatchToBehavioralRequestDto.value.jobSurveyId =
        dropDownSetup.selectedJobSurvey;
      exportJOBMatchToBehavioralRequestDto.value.behavioralLocationFolderId =
        dropDownSetup.selectedBehavioralFolder;
      exportJOBMatchToBehavioralRequestDto.value.clientId =
        dropDownSetup.selectedClient;

      store.commit(Mutations.PURGE_EXCEL_FILE);

      store
        .dispatch(
          Actions.EXPORT_JOB_MATCH_TO_BEHAVIORAL_SURVEYS_TO_EXCEL,
          exportJOBMatchToBehavioralRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true;
        // Activate indicator
        exportToExcelButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    return {
      currentUser,
      jobFolderRequest,
      licensedDistributorId,
      islicensedDistributorIdReady,
      licensedDistributorAssocId,
      islicensedDistributorAssocIdReady,
      currentClient,
      isCurrentClientReady,
      areResultsReady,
      jobMatchToBehavioralSurveyRequestDto,
      jobSurveyToMatch,
      ...toRefs(dropDownSetup),
      getClients,
      getClientFolders,
      getJOBSurveys,
      licensedDistributors,
      clientList,
      clientFolderList,
      clientJobFolderList,
      jobSurveyList,
      jobSurveyMatchResults,
      filterSurveys,
      searchSurveys,
      validateForm,
      onSubmitRunReport,
      runReportButton,
      exportToExcelButton,
      resetResultAreReady,
      exportToExcel,
      excelFileResponse,
      isExcelFileReady,
    };
  },
});
</script>
<style lang="scss">
/* Responsive Code */

/* Small devices<576px */
@media (max-width: 575px) {
}

/* Small devices ≥576px */
@media (min-width: 576px) {
  .w-21-sm {
    width: 21% !important;
  }
  .image-sm-width {
    width: 50%;
  }
}

/* Medium devices ≥768px */
@media (min-width: 768px) {
  .image-md-width {
    width: 37%;
  }
}

/* Large devices ≥992px */
@media (min-width: 992px) {
}

/* Extra large devices ≥1200px */
@media (min-width: 1200px) {
}
</style>
